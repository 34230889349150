<template>
	<div class="container" @click="toggle">
		<div v-if="show" @click.stop="toggle" class="outside"></div>	<!-- Captures any clicks outside the box -->

		<img src="@/assets/icons/login.svg">

		<div v-if="show" class="box">
			<div id="arrow"></div>
			<button @click.stop="register">Register</button>
			<button @click.stop="login">Login</button>
		</div>
	</div>
</template>

<script>

	export default {
		name: "SettingsButton",
		data() {
			return {
				show: false
			};
		},
		methods: {
			toggle(e) {
				this.show = !this.show;
			},
			register() {
				this.$store.cleanUserSubscriptions();
				this.$router.push({ path: "/sign-in" });				
			},
			login() {
				this.$store.cleanUserSubscriptions();
				this.$router.push({ path: "/sign-in", query: { view: 'login' } });				
			},
			redirect(path) {
				this.$router.push({ path: path });
			}
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		position: relative;
		padding: 10px;

		.outside {
			position: fixed;
		    padding: 0;
		    margin: 0;
		    top: 0;
		    left: 0;
		    width: 100vw;
		    height: 100vh;
		    z-index: 0;
		}

		img {
			@extend .pointer;
			@extend .noselect;
			@extend .nodrag;
			width: 100%;
			// height: 40px;

			&:hover {
				@include popup;
			}
		}
		.box {
			@include box-shadow;
			padding: 10px;
			position: absolute;

			margin-left: -155px;
			top: 50px;
			width: 200px;

			z-index: 99;
			background-color: var(--dark);
			border-radius: 10px;

			button {
				@include button;
				width: 90%;
			}
			#arrow {
				position: absolute;
				top: -20px;
				left: 160px;
				width: 0;
				height: 0;

				// Make a triangle
				border: 10px solid transparent;
				border-bottom: 10px solid var(--dark);
			}
		}
	}
</style>
