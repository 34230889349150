<template>
  <nav>
    <div class="grid-container">
      <div class="logo" @click="onLogoClick()">
        <img class="logo" src="@/assets/img/logo-efan/logo_efan_072_1280px.png" />
      </div>

      <!-- <div class="search">
        <SearchBar class="search" />
      </div> -->

      <div class="menu">
        <!-- <img
          src="@/assets/icons/groups.svg"
          title="Home"
          @click="redirect('/sign-in')"
        /> -->
        <img class="hidden" src="@/assets/icons/tags.svg" title="Tags" @click="openTagModal" />
        <!-- <img src="@/assets/icons/notifications.svg" title="Notifications" @click="redirect('/sign-in')"> -->
        <!-- <img
          src="@/assets/icons/group.svg"
          title="Friends"
          @click="redirect('/sign-in')"
        /> -->
        <img src="@/assets/icons/tags.svg" title="Tags" @click="openTagModal" />
        <img class="hidden" src="@/assets/icons/streams.svg" title="Streams" @click="redirect('/sign-in')">

        <!-- <img src="@/assets/icons/chat.svg" title="Chat" @click="redirect('/sign-in')"> -->
        <img
          src="@/assets/icons/calendar.svg"
          title="Calendar"
          @click="redirect('/calendar/open')"
        />
        <!-- <SettingsButton />  -->

      </div>

      <!-- <div class="profile">
        <img
          class="profileImg"
          :src="profileImg"
          title="My profile"
          @click="redirect('/sign-in')"
        />
        <h3 @click="redirect('/sign-in')">Guest</h3>
      </div> -->

      <div class="settings">
        <!-- <NotificationButton /> -->
        <!-- <img src="@/assets/icons/tags.svg" title="Tags" @click="openTagModal" /> -->
        <SettingsButton /> 
      </div>
    </div>
  </nav>
</template>

<script>
import SearchBar from "@/components/open/SearchBarOpen"
import NotificationButton from "@/components/open/NotificationButtonOpen"
import SettingsButton from "@/components/open/SettingsButtonOpen"

export default {
  name: "NavbarOpen",
  components: { SearchBar, NotificationButton, SettingsButton },
  data() {
    return {
    }
  },
  methods: {
    onLogoClick() {
      this.$emit('refreshFeed')
      this.redirect('/landing')
    },
    redirect(path) {
      this.$router.push({ path: path })
    },
    openTagModal() {
      if(this.$route.path === '/calendar/open') {
        this.$router.push({ path: '/landing' })
      } else {
        this.$emit("openmodal", true)
      }
    },
  },
  computed: {
    profileLink() {
      return "/user/" + this.$store.getUsername()
    },
    profileImg() {
      let img = this.$store.getImage()
      return this.$formatter.formatProfileImg(img)
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  @include respond-to("small") {
    max-width: 100vw;
  }
}

.menu img[title="Streams"],
.menu img[title="Notifications"],
.menu img[title="Chat"],
.search {
  @include respond-to("small") {
    display: none !important;
  }
}

.grid-container {
  @include gradient;

  display: grid;
  grid-template-columns: 0.5fr 1.2fr 1.6fr 1.1fr 0.6fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-template-areas: "logo search menu profile settings";
  padding: 0px;

  @include respond-to("medium") {
    grid-template-columns: 0.9fr 1.2fr 0.9fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "logo logo search"
      "menu menu settings";

    .profile {
      margin-left: 20px;
      .profileImg {
        top: 0 !important;
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  @include respond-to("small") {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "logo logo"
      "menu settings";

    .profile {
      margin-left: 10px;
      .profileImg {
        top: 0 !important;
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  .logo {
    @extend .nodrag;
    @extend .noselect;
    @extend .pointer;
    @include white-filter;

    grid-area: logo;
    margin-left: 40px;
    width: 150px;

    @include respond-to("medium") {
      margin-left: 10px;
    }

    @include respond-to("small") {
      width: 120px;
      margin-top: 4px;
    }
  }

  .search {
    grid-area: search;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to("medium") {
      margin-right: 10px;
    }
  }

  .menu {
    grid-area: menu;
    margin-right: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;

    @include respond-to("small") {
      justify-content: inherit;
      // justify-content: space-between;
    }

    .hidden {
      visibility: hidden;
        @include respond-to("small") {
          display: none;
        }
    }

    img {
      @extend .nodrag;
      @extend .noselect;
      @extend .pointer;
      @include white-filter;

      height: 40px;
      padding: 10px;

      &:hover {
        @include popup;
      }
    }
  }

  .profile {
    grid-area: profile;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to("medium") {
      justify-content: start;
    }

    .profileImg {
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;
      @include profile-img(50px);

      position: relative;
      // top: 15px;
    }
    h3 {
      @extend .pointer;

      color: var(--text-light);
      padding: 0 10px 0 10px;
    }
  }

  .settings {
    grid-area: settings;
    padding-right: 20px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @include respond-to("medium") {
      padding-right: 0;
      justify-content: flex-end;
      margin-right: 10px;
    }

    * {
      width: 35px;
      height: 35px;
    }
  }
}
</style>
