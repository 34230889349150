<template>
  <article v-if="!isDeleted">
    <div class="info">
      <img id="profileImg" :src="profileImg" @click="redirect()" />

      <h3 v-if="isAdminPost">{{ setAdminTitle }}</h3>
      <!-- Admin post -->
      <h3 v-else-if="isGroupPost" @click="redirect()">{{ username }}</h3>
      <!-- Group post -->
      <h3 v-else @click="redirect()">{{ username }}</h3>
      <!-- User  post -->

      <h4>{{ profileType }}</h4>
      <!-- Type of profile -->
    </div>

    <div v-if="!isAdminPost && !isGroupPost" class="tags" :title="formattedTags">
      <span
        class="tag"
        v-for="(tag, index) in tagsArray"
        :key="index"
        >{{ tag }}
      </span>
      <span
        v-if="!isAdminPost && !isGroupPost && tagButtonVisible"
        class="tag extendTagsButton"
        @click="extendTags = true"
      >
        ...
      </span>
    </div>

    <div class="postContent">
      <p v-html="formatted"></p>
      <!-- Post text -->

      <img v-if="hasImage" id="postImg" :src="img_url" @click="redirect()"/>
      <!-- Post image -->

      <LinkPreview
        v-if="!hasImage"
        :title="preview_title"
        :img="preview_img"
        :url="preview_url"
      />
      <!-- Post link preview -->
      <static-table :table-data="tableData"></static-table>
    </div>

    <div class="postInfo" @click="redirect()">
      <p>{{ setComments }}</p>
      <p>{{ $formatter.formatTimeSince(date_posted) }}</p>
    </div>

    <CommentForm :postId="id" @refresh="refreshComments" @click.native="redirect()" />
  </article>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Comments from "@/components/post/Comments"
import CommentForm from "@/components/open/CommentFormOpen"
import LinkPreview from "@/components/post/LinkPreview"
import StaticTable from "@/components/StaticTable"

export default {
  name: "Post",
  components: {
    StaticTable,
    Comments,
    CommentForm,
    LinkPreview,
  },
  props: {
    id: Number,
    text: String,
    img_url: String,
    date_posted: Number,
    tags: String,
    username: String,
    user_img: String,
    id_user: Number,
    admin: Number,
    group: Number,
    id_group: Number,
    preview_title: String,
    preview_img: String,
    preview_url: String,
    exposed: Number,
    type: String,
    creator: Boolean,
    tableData: {
      type: Object,
      default() {
        return {
          columns: [],
          rows: [],
        }
      },
    },
  },
  data() {
    return {
      comments: [],
      commentKey: 1,
      extendTags: false,
      showTableEditor: false,
      isDeleted: false,
      showDeleteModal: false,
      editableTableData: {},
      adminPost: {
        title: "",
        img: "",
      },
    }
  },
  async mounted() {
    this.getComments()
  },
  methods: {
    redirect() {
      this.$router.push({ path: "/sign-in" })
    },
    refreshComments() {
      this.commentKey++
    },
    newObject() {
      return JSON.parse(this.text).tbl
    },
    async getComments() {
      let response = await this.$api.getComments(this.id, this.userStore.accessToken)

      if (response.success === true) {
        this.comments = response.comments
      } else {
        this.comments = []
      }
    }
  },
  computed: {
    ...mapStores(useUserStore),
    profileImg() {
      if (this.isGroupPost && this.user_img === "")
        return require("@/assets/img/defaultGroup.jpg")
      if (this.isAdminPost) {
        // V primeru, da admin slika obstaja zaženi ta del
        if (this.$store.adminImgExists()) {
          let adminImg = this.$store.getAdminImg()
          if (adminImg) {
            return adminImg
          } else {
            return require("@/assets/img/default.jpg")
          }
        } else {
          return require("@/assets/img/default.jpg")
        }
      }

      return this.$formatter.formatProfileImg(this.user_img)
    },
    setComments() {
      return this.comments.length === 1
        ? this.comments.length + " Comment"
        : this.comments.length + " Comments"
    },
    setAdminTitle() {
      let adminTitle = this.$store.getAdminPostName()
      if (
        adminTitle.length > 0 &&
        adminTitle !== undefined &&
        adminTitle !== ""
      ) {
        return adminTitle
      } else {
        return "Post Admin"
      }
    },
    formattedTags() {
      return this.tags.replaceAll(",", ", ")
    },
    tagsArray() {
      if (this.extendTags) return this.tags.split(",")
      else return this.tags.split(",").slice(0, 3)
    },
    tagButtonVisible() {
      return this.tags.split(",").length > 3 && this.extendTags === false
    },
    isAdminPost() {
      return this.admin === 1
    },
    isGroupPost() {
      return this.group === 1
    },
    formatted() {
      let message = ""
      if (this.type === "table") {
        let tableData = JSON.parse(this.text)
        message = tableData.msg
        this.tableData.columns = tableData.tbl.columns
        this.tableData.rows = tableData.tbl.rows
      } else {
        message = this.text
      }

      // Find all URL's in posts text and format it into html
      const urlMatcher = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gim
      return message.replace(
        urlMatcher,
        (match) =>
          `<a target="_blank" rel="noopener noreferrer" style="color: orange" href="${match}">${match}</a>`
      )
    },
    hasImage() {
      return (
        this.img_url !== null &&
        this.img_url !== undefined &&
        this.img_url !== ""
      )
    },
    profileType() {
      if (this.isAdminPost) return "by EFAN admins"
      if (this.isGroupPost) return "Group post"
      return "Personal profile"
    },
  },
  async mounted() {
    this.editableTableData = this.assignNewObject
  },
}
</script>

<style lang="scss" scoped>
@keyframes removedFadeout {
  0% {
    opacity: 1;
    height: 5em;
  }
  80% {
    opacity: 1;
    height: 5em;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
article {
  margin: 0 0 60px;

  text-align: center;
  color: var(--text);
  border-top: 3px solid var(--dark);
  padding-top: 10px;

  &.deleted {
    height: 5em;
    line-height: 5em;
    padding-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    color: var(--text-darker);
    animation: removedFadeout 5s ease-out both;
  }

  .remove-modal {
    z-index: 100;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--background-light);
    padding: 32px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 30px var(--background-dark);
    display: none;
    &.show {
      display: grid;
    }
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "text"
      "buttons";
    span {
      grid-area: text;
    }
    .controls {
      grid-area: buttons;
      button {
        @include button(10px, false);
        margin-top: 2em;
      }
    }
  }

  @include respond-to("small") {
    margin-left: 0;
    margin-right: 0;

    .remove-modal {
      padding: 16px;
    }
  }

  .info {
    display: grid;
    grid-template-columns: 70px auto 50px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "image name controls"
      "image type bottomControls";

    padding: 8px;
    text-align: left;

    #adminImg {
      @include box-shadow($color: rgba(0, 0, 0, 0.3));
      max-width: 100%;
      max-height: 400px;
      border-radius: 50%;
      margin-right: 20px;

      transition: all 0.4s ease-in-out 0.5s;
      &:hover {
        transform: scale(1.15);
        z-index: 9999;
      }
    }

    h3 {
      // User info
      @extend .pointer;
      @include dot-overflow;
      grid-area: name;
      margin: 0;
      margin-top: 5px;

      font-size: 1.3em;
    }
    h4 {
      // Type of profile
      margin: 0;
      font-size: 0.8em;
      font-weight: normal;
      grid-area: type;
    }
    #profileImg {
      grid-area: image;
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;

      @include profile-img(60px);
      @include box-shadow($color: rgba(0, 0, 0, 0.2));
    }

    .post-controls {
      grid-area: controls;
      .b-dropdown::v-deep {
        > button {
          transform: rotateZ(0deg);
          line-height: 0;
          transition: all 0.3s;
          &:hover {
            transform: rotateZ(0deg) scale(1.05, 1.05) !important;
          }
        }
        &.show {
          > button {
            transform: rotateZ(90deg) !important;
          }
        }
        button {
          @include button(10px, false, var(--medium));
        }
        .dropdown-menu {
          position: absolute;
          display: none;
          z-index: 10;
          background: var(--background-dark);
          padding: 8px 0;
          box-shadow: 3px 3px 10px var(--background-dark);
          outline: none;
          &.show {
            display: initial;
          }
          li {
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            button {
              display: flex;
              justify-content: space-between;
              width: inherit;
              margin: 0;
              background: transparent;
              border-radius: 0;
              white-space: nowrap;
              padding: 4px 10px;
              &:hover {
                background: var(--medium);
              }
              svg {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .tags {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 5px;

    .tag {
      padding: 8px;
      margin-right: 5px;
      margin-top: 4px;
      border-radius: 15px;
      background-color: var(--dark);
      font-size: 0.8em;
    }
    .extendTagsButton {
      @extend .pointer;
      @extend .noselect;
    }
  }

  // Post content
  .postContent {
    font-size: 1.2em;
    word-wrap: break-word;
    padding: 5px;
    height: auto;

    p {
      // Post text
      text-align: left;
      margin-top: 15px;
      margin-bottom: 25px;
    }
    #postImg {
      @include box-shadow($color: rgba(0, 0, 0, 0.3));
      max-width: 100%;
      max-height: 400px;
      border-radius: 15px;

      transition: all 0.4s ease-in-out 0.5s;
      &:hover {
        transform: scale(1.15);
        z-index: 9999;
      }
    }
  }
  // Post info
  .postInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;

    p {
      color: var(--light);
      font-size: 0.9em;
      margin: 0;
      padding: 10px;
      max-width: 400px;
    }
  }
}
</style>
