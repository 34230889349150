<template>
	<div class="container" @click="toggle">
		<div v-if="show" @click.stop="toggle" class="outside"></div>	<!-- Captures any clicks outside the box -->
		<div class="button">
			<img src="@/assets/icons/notifications.svg">
		</div>
		<div v-if="show" class="box" @click="redirect('/sign-in')">
			<div id="arrow"></div>
			<h3 v-if="notifications.length === 0">Sign up</h3>
		</div>
	</div>
</template>

<script>

	export default {
		name: "NotificationButtonOpen",
		data() {
			return {
				show: false,
				notifications: []
			};
		},
		methods: {
			toggle() {
				this.show = !this.show;
			},
      redirect(path) {
        this.$router.push({ path: path });
      }
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		position: relative;
		padding: 10px;

		.outside {
			position: fixed;
		    padding: 0;
		    margin: 0;
		    top: 0;
		    left: 0;
		    width: 100vw;
		    height: 100vh;
		    z-index: 0;
		}

		.button {
			@extend .pointer;
			@extend .noselect;
			@extend .nodrag;
			position: relative;

			img {
				width: 100%;
				height: 100%;
			}
			#unreadCount {
				position: absolute;
				top: -8px;
				left: -3px;

				color: var(--dark);
				background-color: var(--text);
				font-weight: bold;
				border-radius: 50%;

				padding: {
					right: .3em;
					left: .3em;
					top: .1em;
					bottom: .1em;
				};
			}
		}

		.box {
			@include box-shadow;
			padding: 5px;
			position: absolute;

			margin-left: -155px;
			top: 50px;
			width: 200px;

			z-index: 99;
			background-color: var(--dark);
			border-radius: 10px;

			h3 {
				text-align: center;
				padding: 5px;
				color: var(--text);
			}
			#arrow {
				position: absolute;
				top: -20px;
				left: 160px;
				width: 0;
				height: 0;

				// Make a triangle
				border: 10px solid transparent;
				border-bottom: 10px solid var(--dark);
			}
		}
	}
</style>
