<template>
	<div class="container">
		<img src="@/assets/img/default.jpg" @click="redirect">
		<input
			type="text"
			v-model="text"
			placeholder="Sign up for commenting ..."
			maxlength=250 @keyup.enter="redirect">
	</div>
</template>

<script>
	export default {
		name: "CommentForm",
		props: {
			postId: Number
		},
		data() {
			return {
				text: ""
			};
		},
    methods: {
      redirect() {
        this.$router.push({path: "/sign-in"});
      },
    }
	}
</script>

<style lang="scss" scoped>
	.container {
		display: flex;
		padding: 5px;

		img {
			@include profile-img(40px);
			margin-right: 10px;
      cursor: pointer;
		}
		input[type="text"] {
			@include text-input;
			@include remove-outline;
			@include placeholder {
				color: var(--medium);
			}
			background-color: var(--dark);
			color: var(--light);
			width: 100%;
      cursor: pointer;
		}
	}
</style>
