<template>
	<div class="container">
		<div class="cover">
			<img class="coverImg" src="@/assets/img/background.jpg">
			<img class="profileImg" src="@/assets/img/default.jpg" @click="redirect">
		</div>

		<h1>Guest</h1>
		<h2>GuestGamer{{Math.floor(Math.random()*1000)}}</h2>

		<div class="fluid-header">
      <h1>My tags</h1>
      <button class="edit-tags">
        <svg @click="openTagsModal" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
      </button>
    </div>
		<h2 class="tags">{{ formattedTags }}</h2>
	</div>
</template>


<script>
	const isEmpty = x => (x === false || x === undefined || x === null || x === "null" || x === "");

	//import EditIcon from "@/assets/icons/edit-2.svg?inline";

	export default {
		name: "MyProfileOpen",
		//components: {EditIcon},
		props: {
			refreshTag: false
		},
		data() {
			return {
				tags: this.$store.getSubscribed() ? this.$store.getSubscribed() : [],
			};
		},
    watch: {
      refreshTag: function(val, oldVal) {
        if (oldVal === false && val === true) {
          this.formattedTags = this.$store.getSubscribed();
        }
      }
    },
		methods: {
			redirect() {
				this.$router.push({path: "/sign-in"});
			},
			openTagsModal() {
        this.$emit("openmodal", true);
			}
		},
		computed: {
      formattedTags: {
        get: function () {
          return this.tags.slice(0, 5).map(x => x.name).join(", ") + ((this.tags.length > 5) ? ", ..." : "");
        },
        set: function (newData) {
          this.tags = newData ? newData : [];
        }
      }
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		@include side-component;
		text-align: center;
		padding: 10px;
    position: relative;

    .fluid-header {
      display: flex;
      justify-content: center;
    }
    .edit-tags {
      @include button(5px, false, transparent);
      right: 10px;
    }

		.cover {
			position: relative;
			width: 100%;
			margin-bottom: 35px;

			.coverImg {
				width: calc(100%);
				height: 40%;
				background-color: var(--medium);
				border-radius: 15px;
			}
			.profileImg {
				@include profile-img(80px);
				@extend .pointer;
				position: absolute;
				bottom: -35px;
				left: calc(50% - 47px);
				border: 7px solid var(--dark);
			}
		}
		.coverImgOnly {
			img {
				@include profile-img(80px);
				@extend .pointer;
			}
		}
		h1 {
			color: var(--text);
			font-size: 1.1em;
		}
		h2 {
			color: var(--text-darker);
			font-size: .9em;
		}
		.tags {
			@include center;
			width: 80%;
		}
	}
</style>
