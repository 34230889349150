<template>
	<div @click="mark"
		 :class="{ marked: marked, unmarked: !marked }"
		 :title="name">
		<h4>{{ name }}</h4>
	</div>
</template>

<script>
	export default {
		name: "TagSticker",
		props: {
			id: Number,
			name: String,
			subscribed: Boolean
		},
		data() {
			return {
				marked: this.subscribed
			};
		},
		methods: {
			mark() {
        		this.marked = !this.marked;
        		this.$emit("changed", this.id, this.name);
			}
		}
	}
</script>

<style lang="scss" scoped>
	div {
		@extend .pointer;
		@extend .noselect;

		display: flex;
		align-items: center;
		justify-content: center;

		max-width: 33%;
		max-height: 1em;
		margin: 3px;
		padding: 10px;

		background-color: var(--light);
		border-radius: 10px;

		h4 {
			@include dot-overflow;
			font-size: 0.8em;
			font-weight: normal;
			color: var(--dark);
		}
	}
	.marked {
		background-color: var(--orange);
	}
</style>
