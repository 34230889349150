<template>
	<div class="container">
		<component class="element" v-for="(component, index) in components" :key="index" :is="component" @openmodal="openTagsModal"
               :refreshTag="refresh" />
	</div>
</template>

<script>
	import UserGroups from "@/components/open/UserGroupsOpen";
	import Suggestions from "@/components/side/Suggestions";
	import TagSuggestions from "@/components/side/TagSuggestions";
	import UpcomingEvents from "@/components/open/UpcomingEventsOpen";
	import StreamList from "@/components/side/StreamList";
	import MyProfile from "@/components/open/MyProfileOpen";

	export default {
		name: "ComponentColumn",
		components: {UserGroups, Suggestions, TagSuggestions, UpcomingEvents, StreamList, MyProfile},
		props: {
			components: Array,
      refresh: false
		},
		methods: {
			openTagsModal() {
        this.$emit("openmodal", true);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		.element {
			margin: {
				bottom: 20px;
			}
		}
	}
</style>
