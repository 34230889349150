<template>
	<div>
		<img src="@/assets/icons/search.svg" @click="search">
		<input v-model="query" type="text" maxlength=20 @keyup.enter="search">
	</div>
</template>

<script>
	export default {
		name: "SearchBar",
		data() {
			return {
				query: ""
			};
		},
		methods: {
			search() {
        this.$router.push({path: "/sign-in"});
				this.query = "";
			}
		}
	}
</script>

<style lang="scss" scoped>
	div	{
		white-space: nowrap;

		img {
			@extend .pointer;
			@extend .noselect;
			@extend .nodrag;
			@include white-filter;

			position: relative;
			right: -40px;

			vertical-align: top;
			display: inline-block;
			padding: 3px;
			border-radius: 15%;
			height: 30px;
		}

		input {
			display: inline-block;
			height: 30px;
			width: 200px;
			padding: 3px;
			margin: 0;
			vertical-align: top;

			text-align: center;
			border: none;
			border-radius: 15px;
			color: var(--text);
			background-color: var(--dark);
		}
	}
</style>
