<template>
	<div class="container" v-bind:class="{groups:embed === false}">
		<h3>{{ text }}</h3>

		<div v-if="public.length > 0" class="groups">
			<div
				class="group"
				v-for="group in public"
				:key="group.id"
				@click="redirect">

				<img :src="$formatter.formatGroupImg(group.profile_img_url)">
				<span>
					<h4>{{ group.name }}</h4>
					<h5>Public group</h5>
				</span>
			</div>
		</div>
		<div v-else class="alert">
			<img src="@/assets/icons/alert-triangle.svg" class="filter-white">
			<h4>No public groups available.</h4>
		</div>
	</div>
</template>

<script>
	export default {
		name: "UserGroupsOpen",
		props: {
		  embed: true,
			id: {
				type: Number,
				default: -1
			},
			form: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
        public: [],
				show: true
			};
		},
		mounted() {
			this.getGroups();
		},
		methods: {
			redirect() {
				let url = "/sign-in";
				this.$router.push({path: url});
			},
			async getGroups() {
				// public groups
        let response2 = await this.$api.getPublicGroupsForGuests();
        if (response2.success === true) {
          this.public = response2.groups;
        }
			},
			toggle() {
				this.show = !this.show;
			}
		},
		computed: {
			text() {
				return "GROUPS";
			}
		}
	};
</script>

<style lang="scss" scoped>
	.container	{
		@include side-component;
		@include scrollbars($radius: 10px, $size: 15px);

		position: relative;
		max-height: 300px;
		padding: 10px;
		text-align: center;
		overflow-y: auto;

    &.groups {
      max-height: initial;
    }

		.createGroupButton {
			@extend .pointer;
			@extend .noselect;
			@extend .nodrag;

			position: absolute;
			top: 10px;
			right: 10px;

			&:hover {
				@include popup;
			}
		}
		h3 {
			@include side-component-title;
			margin-bottom: 15px;
		}
		.groups {
			.group {
				@extend .pointer;
				display: flex;
				margin-top: 10px;
				padding: 5px;

				&:hover {
					background-color: var(--medium-darker);
					border-radius: 10px;
				}

				img {
					display: inline-block;
					@include profile-img(70px);
				}
				span {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					margin-left: 10px;
					@include dot-overflow;

					h4 {
						@include dot-overflow;
						margin: 0;
					}
					h5 {
						margin: 0;
						color: var(--medium);
					}
				}
			}
		}

		.alert {
			padding: 10px;
			h4 {
				margin: 0;
			}
			img {
				width: 30px;
			}
		}
	}
	.filter-white {
		filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);
	}
</style>
