<template>
  <div class="container" :class="{ blurred: tagModalOpen }">
    <Navbar class="navbar" @openmodal="tagModalOpen = true" @refreshFeed="feedKey++"/>

    <div class="grid-container home-page">
      <TagsModal @closemodal="closeAndRefresh" :open="tagModalOpen" />
      <ComponentColumn
        @openmodal="tagModalOpen = true"
        class="leftColumn"
        :refresh="refresh"
        :components="side.left"
      />
      <Feed
        class="feed"
        @openmodal="tagModalOpen = true"
        @refreshed="refresh = false"
        :refresh="refresh"
        :key="feedKey"
      />
      <ComponentColumn class="rightColumn" :components="side.right" />
    </div>
  </div>
</template>

<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "../stores/UserStore";
// Components
import Feed from "@/components/open/FeedOpen";
import Navbar from "@/components/open/NavbarOpen";
import TagsModal from "@/components/open/TagSelectorModal";
import ComponentColumn from "@/components/open/ComponentColumnOpen";


export default {
  name: "LandingPage",
  components: { Navbar, Feed, ComponentColumn, TagsModal },
  data() {
    return {
      side: {
        right: ["UserGroups", "UpcomingEvents", "StreamList"],
        left: ["MyProfile"],
      },
      feedKey: 0,
      tagModalOpen: !(
        this.$store.getSubscribed() && this.$store.getSubscribed().length > 0
      ),
      refresh: false,
    };
  },
  computed: {
    ...mapStores(useUserStore),
  },
  methods: {
    closeAndRefresh() {
      this.tagModalOpen = false;
      this.refresh = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  &.blurred {
    .navbar,
    .feed,
    .leftColumn,
    .rightColumn {
      filter: blur(5px);
    }
    height: 100vh;
  }
  transition: filter 0.3s;
}

.navbar {
  top: 0;
  width: 100%;
  z-index: 100;
}
.grid-container {
  display: grid;
  grid-template-columns: 15vw 20vw 30vw 20vw 15vw;
  grid-template-rows: auto;
  gap: 0px 20px;
  grid-template-areas: ". leftColumn Feed rightColumn .";

  margin-top: 20px;

  @include respond-to("large") {
    grid-template-columns: 00vw 27vw 65vw 0vw;
    grid-template-areas: ". leftColumn Feed .";
  }
  @include respond-to("small") {
    grid-template-columns: 0vw 90vw 0vw;
    grid-template-rows: auto auto;
    grid-template-areas: ".  Feed .";
  }
}
.feed::v-deep {
  grid-area: Feed;

  .container {
    form {
      @include respond-to("small") {
        margin: 0;
      }
    }
    .posts article {
      @include respond-to("small") {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
.leftColumn {
  grid-area: leftColumn;

  @include respond-to("small") {
    display: none;
  }
}
.rightColumn {
  grid-area: rightColumn;

  // @include respond-to("small") {
  //   display: none;
  // }

  // @include respond-to("medium") {
  //   display: none;
  // }
}
</style>
