<template>
  <div class="main tag-modal" :class="{open: open}">
    <section>
      <!-- <h1>Create an account, log in or just browse content on efan.gg. We are a social network designed for gamers.</h1> -->
      <h2>Please select TAGs you want to follow on efan.gg</h2>
      <TagSearch
          class="search"
          v-if="ready"
          :tags="tags"
          :n="20"
          :trigger="trigger"
          @updated="updated"
      />
      <div class="tagContainer all">
        <h2>Available tags</h2>
        <div class="tags">
          <TagSticker
              v-for="tag in filtered"
              :key="tag.id"
              v-bind="tag"
              @changed="updateSubscription"
          />
        </div>
      </div>
      <button type="button"  @click="saveAndRefresh" class="homeBtn">CONTINUE</button>
    </section>
  </div>
</template>

<script>
import TagSticker from "@/components/open/TagStickerOpen";
import TagSearch from "@/components/tag/TagSearch";

import { mapWritableState } from "pinia";
import { useUserTagStore } from "@/stores/tagsStore.js";


export default {
  name: "TagSelectorModal",
  components: {TagSticker, TagSearch},
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tags: [],		// All tags in database
      filtered: [],	// Tags matching search query
      subscribed: [],	// Tags the user is subbed to
      ready: false,
      trigger: 0,
      firstTime: false,

      newTag: {
        formVisible: false,
        name: ""
      }
    };
  },
  mounted() {
    this.generateTags();
    this.firstTime = this.$route.query.f;
  },
  methods: {
    updateSubscription(tagId, name) {
      this.$store.updateSubscribed(tagId, name, true);
      this.generateTags();
    },
    saveAndRefresh() {
      this.hasTags = this.subscribed;
      this.$emit("closemodal", true);
    },
    async generateTags() {
      this.tags.length = 0;	// Clear tags array

      let allTags = (await this.$api.getTags()).tags;
      // setSubscribed allways rebuilds the subscribed array      
      if(!this.$store.getSubscribed()) {
        this.$store.setInitSub(allTags);
      }

      let subscribedTags = this.$store.getSubscribed() ? this.$store.getSubscribed() : [/*
        {id_tag:1},
        {id_tag:3},
        {id_tag:5}
      */];

      if (subscribedTags !== false) {
        this.subscribed = subscribedTags.map(x => {
          return  {
            id: x.id_tag,
            name: x.name,
            subscribed: true
          }
        });

        // Populate this.tags
        allTags.forEach(el => {
          let isSubscribed = subscribedTags.findIndex(x => x.id_tag === el.id_tag) !== -1;	// Checks if tag exists in subscribedTags
          this.tags.push({id: el.id_tag, name: el.name, subscribed: isSubscribed});
        });
      }

      this.ready = true;
    },
    updated(filtered, query) {
      this.newTag.formVisible = false;
      this.filtered = filtered;

      if (filtered.length === 0) {
        this.newTag.formVisible = true;
        this.newTag.name = query;
      }
    }
  },
  computed: {
    ...mapWritableState(useUserTagStore, ['hasTags']),
  }
}
</script>

<style lang="scss" scoped>
.main {
  &:not(.open) {
    display: none;
  }

  filter: none !important;
  background-color: transparentize(#18191F, 0.5);
  height: 100vh;
  width: 100%;
  top: 0;

  position: fixed;
  z-index: 100;

  .firstTime {
    @include gradient;
    margin-top: 0;
    padding-bottom: 0;
    height: 100vh;
  }
  section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto 70% auto 10%;
    gap: 20px 0px;
    grid-template-areas:
				"title title"
				"sub-title sub-title"
				"search search"
				"all all"
				"button button"
				". .";

    margin-top: 60px;

    h1 {
      grid-area: title;
      max-width: 90%;
      margin: 0 auto;
      text-align: center;
      color: var(--text);
      margin-top: 80px;

    }
    h2 {
      margin-top: 50px;
      grid-area: sub-title;
      text-align: center;
      color: var(--text);
    }
    h3 {
      color: var(--text);
    }
    .search {	// TagSearch component
      grid-area: search;
      @include center;
    }
    .tagContainer {
      @include box-shadow($color: rgba(0,0,0,0.2), $size: 3px);
      width: 70%;
      height: 90%;
      margin: 0 auto;
      padding: 10px;
      background-color: var(--text);
      border-radius: 15px;

      @include respond-to('small') {
        width: 85vw !important;
        margin-left: 20px;
      }

      h2 {
        color: var(--text-2);
        text-align: center;
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        padding-bottom: 10px;

        max-height: 80%;

        overflow: auto;
        @include scrollbars($foreground-color: var(--medium), $background-color: var(--light), $radius: 5px);
      }
    }
    .subscribed {
      grid-area: subscribed;

      .noSubscribedTags {
        color: var(--text-2);
      }
    }
    .all {
      grid-area: all;

      #newTagForm {
        padding: 10px;
        margin: 5px;

        background-color: var(--orange);
        border-radius: 15px;

        h3 {
          color: var(--text);
        }
        h4 {
          color: var(--text-2);
        }
        input {
          @include remove-outline;
          padding: 10px;
          border-radius: 10px;
          border: none;
        }
      }
    }

    .homeBtn {
      grid-area: button;
      @include button($padding: 15px, $color: white, $radius: 15px);
      @include box-shadow($color: rgba(0,0,0,0.2));
      @include center;
      color: var(--dark);
      font-weight: bold;
      width: 110px;
    }
  }

  @include respond-to('medium') {
    section {
      grid-template-rows: auto auto auto auto auto 2%;
      margin-top: 10px;

      .tagContainer {
        width: 90%;
      }
    }
  }
}
</style>
